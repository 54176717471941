<template>
    <div class="o365-rich-textarea position-relative">
        <template v-if="control.editMode || editMode">
            <slot name="toolbar" v-if="!hideToolbar">
                <div class="d-flex">
                    <!-- <button class="btn btn-sm btn-link" @click="control.addHeading()" :title="$t('Add heading text')"><i class="bi bi-type-h3"></i></button> -->
                    <button class="btn btn-sm btn-link" 
                        @click="control.addBoldText()" 
                        v-tooltip="$t('Add bold text, <Ctrl + b>')">
                        <i class="bi bi-type-bold"></i>
                    </button>
                    <button class="btn btn-sm btn-link" 
                        @click="control.addItalicText()" 
                        v-tooltip="$t('Add italic text, <Ctrl + i>')">
                        <i class="bi bi-type-italic"></i>
                    </button>
                    <!--<button class="btn btn-sm btn-link" @click="control.addQuate()" :title="$t('Add a quate')"><i class="bi bi-blockquote-left"></i></button> -->
                    <button class="btn btn-sm btn-link" 
                        @click="control.addCode()" 
                        v-tooltip="$t('Add code, <Ctrl + e>')">
                        <i class="bi bi-code"></i>
                    </button>
                    <button class="btn btn-sm btn-link" 
                        @click="control.addLink()" 
                        v-tooltip="$t('Add a link, <Ctrl + k>')">
                        <i class="bi bi-link-45deg"></i>
                    </button>
                    <button class="btn btn-sm btn-link" 
                        @click="control.addBulletedList()" 
                        v-tooltip="$t('Add a bulleted list')">
                        <i class="bi bi-list-ul"></i>
                    </button>
                    <!-- <button class="btn btn-sm btn-link" @click="control.addNumberedList()" :title="$t('Add a numbered list')"><i class="bi bi-list-ol"></i></button> -->
                    <a class="btn btn-sm btn-link ms-auto" 
                        href="https://docs.github.com/en/get-started/writing-on-github/getting-started-with-writing-and-formatting-on-github/basic-writing-and-formatting-syntax" 
                        target="_blank"
                        v-tooltip="$t('Styling with Markdown is supported')">
                        <i class="bi bi-markdown"></i>
                    </a>
                </div>
            </slot>
            <OTextArea v-model="internalModel" 
                @vue:mounted="onTextAreaMounted" @vue:beforeUnmount="onTextAreaBeforeUnmount" 
                :rows="rows" :class="textAreaClass" 
                @blur="e =>{ $emit('blur', e); control.handleEditorBlur(e)}" 
                @focus="control.handleEditorFocus($event)" :ref="(cmp) => {control.setupTextArea(cmp)}" 
                v-bind="textAreaOptions"/>
        </template>
        <template v-else>
            <MarkedPreview :class="previewClass" @dblclick="control.handlePreviewClick($event)" :value="modelValue" :style="{'min-height': 28*rows+6+'px'}" :markedOptions="{ breaks: true}"/>
            <button v-if="!hideEditButton" class="position-absolute btn btn-sm btn-link top-0 end-0 o365-rich-textarea-edit" @click="control.handlePreviewClick($event)">{{$t('Edit')}}</button>
        </template>
    </div>
</template>

<script lang="ts">
export interface IRichTextAreaProps {
    modelValue: string | null,
    textAreaClass?: string,
    previewClass?: string,
    rows?: number|string,
    editMode?: boolean,
    hideToolbar?: boolean
    hideEditButton?: boolean;
    textAreaOptions?: any,
}
</script>

<script setup lang="ts">
import { OTextArea } from 'o365-ui-components';
import { vTooltip } from 'o365-vue-utils';
import RichTextAreaControl from 'o365.controls.RichTextAreaControl.ts';
import MarkedPreview from 'o365.vue.components.Marked.vue';
import { computed, reactive } from 'vue';

const props = withDefaults(defineProps<IRichTextAreaProps>(), {
    textAreaClass: 'form-control form-control-sm',
    textAreaOptions: {
        noResize: true,
        autoGrow: true,
    },
    rows: 3
});




const emit = defineEmits<{
    (e: 'update:modelValue', value: string|null): void,
    (e: 'blur', event: Event): void
}>();

const internalModel = computed({
    get() { return props.modelValue ?? ''; },
    set(value) { 
        if( !control.editMode) { control.editMode = true };
        emit('update:modelValue', value);
    }
});

const control = reactive(new RichTextAreaControl({
    props: props,
    getModel: () => props.modelValue??'',
    setModel: (value: string) => { 
        if( !control.editMode) { control.editMode = true };
        emit('update:modelValue', value);
    },
}));

let textAreaScroll = 0;
function onTextAreaMounted(cmp) {
    window.requestAnimationFrame(() => {
        if (cmp?.el) { cmp.el.scrollTop = textAreaScroll; }
    });
}

function onTextAreaBeforeUnmount(cmp) {
    if (cmp?.el) { textAreaScroll = cmp.el.scrollTop; }
}

defineExpose({ control })
</script>

<style scoped>
.o365-rich-textarea .o365-rich-textarea-edit {
    display: none;
}
.o365-rich-textarea:hover .o365-rich-textarea-edit {
    display: block;
}
</style>